<template>
    <div class="signup site-main-wrapper bg-diagonal-lines" role="main">
        <div class="page-background"></div>
        <div class="container signup-container" ng-controller="signupFormCtrl">
            <form class="sectionSignup" id="signupForm" name="signupForm" novalidate>
                <section>
                    <h2 class="blue">Sign Up</h2>
                    <section class="sectionSignupThankYou">
                        <div class="row">
                            <div class="alert alert-success" role="alert" ng-show="signupSuccess" :class="{'ng-hide': isAddHideClassToSingupSuccess}"><span
                                    class="glyphicon glyphicon-ok-sign" aria-hidden="true"></span><span>Your account has
                                    been created successfully and is ready to use. You will receive an email with your
                                    temporary password. Upon log-in you will be prompted to choose a new password</span>
                            </div>
                            <div class="alert alert-danger ng-hide" role="alert" ng-show="signupError"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span><span>An error occurred while creating your account. Please check username availaibilty and try again.</span></div>
                            <div class="alert alert-danger" role="alert" ng-show="returnedSignupError" :class="{'ng-hide': isAddHideClassToSingupError}"><span
                                    class="glyphicon glyphicon-exclamation-sign"
                                    aria-hidden="true"></span><span>{{returnedError}}.</span>
                                <p>Please take a screen capture or copy this error message and send to The Goodyear
                                    Learning Center at <a href="mailto:glc@goodyear.com">glc@goodyear.com</a> for
                                    assistance. </p>
                            </div>
                        </div>
                    </section>
                    <!-- Notification Banner -->
                    <!-- <section>
                        <div class="container-fluid">
                            <div id="sign-up-banner" class="notification-banner" style="padding: 20px 0px 40px 0px;">
                                <div class="notification-banner-inner" style="max-width: 100%;">
                                    <strong class="banner-title"><b-icon icon="exclamation-circle" style="margin-right: 6px; padding-top: 4px;"></b-icon>Upcoming Sign-In Change</strong><br> 
                                    <p lass="banner-p">
                                        Starting <strong>November 5, 2024,</strong> you will need to use your email address, 
                                        instead of a UserID, to log in to your Goodyear Learning 
                                        Center account. Password requirements will also be updated. <br><br>
                                    
                                        <i>More information regarding these updates will be sent to your email soon. If you 
                                        have questions, contact your Goodyear sales representative or email <a href="mailto:glc@goodyear.com" style="color: #06d;">glc@goodyear.com</a>.</i>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section> -->
                    <!-- Notification Banner End -->
                    <h3 class="blue section-header-text first">Step 1: Your Company </h3>
                    <p>Enter the Nonsig# of your Company in the text box below. If your company is not found, contact
                        The Goodyear Learning Center at <a href="mailto:glc@goodyear.com">glc@goodyear.com. </a></p>
                    <div class="row form-group">
                        <div class="col-xs-6 col-md-8"><input class="form-control" type="text" ng-pattern="/^[0-9]*$/" name="nonsig" v-model="user.nonsig" value="" id="nonsig" placeholder="*Nonsig #" :class="{'ng-touched ng-dirty ng-valid-parse ng-invalid ng-not-empty ng-valid-required ng-invalid-pattern': isInvalidnonsig}" @keyup="checkValidNonsig"></div>
                        <div class="col-xs-6 col-md-4"><button class="btn btn-primary" type="button"
                                style="width:95%" @click="checkNonsig">SEARCH</button>
                        </div>
                        <div class="col-xs-12"><img id="spinner" src="/images/spinner5.gif" ng-show="loading" :class="{'ng-hide': isAddHideClassToStep1SpinnerLoading}">
                            <div class="form-message"
                                ng-show="signupForm.nonsig.$error.pattern &amp;&amp; !user.nonsig.length" :class="{'ng-hide': isAddHideClassToNonsigNumbersError}"><span
                                    class="red">Nonsig must only contain numbers. </span></div>
                        </div>
                    </div>
                    <div class="step1_details" :class="{'ng-hide': isAddHideClassToStep1Details}">
                        <div class="row companyDetails" :class="{'ng-hide': isAddHideClassToStep1DetailsInvalid}">
                            <div class="col-xs-12"><span class="glyphicon glyphicon-exclamation-sign red"
                                    > </span><span class="red"> Please enter a valid NonSig
                                    number.</span></div>
                        </div>
                        <div class="companyDetails" :class="{'ng-hide': isAddHideClassToStep1DetailsValid}">
                            <div class="row">
                                <div class="col-xs-12 col-md-6">
                                    <div class="col-md-5 title">Company Name:</div>
                                    <div class="col-md-7"><input type="text" name="companyName" v-model="user.NAME"
                                            value="" readonly tabindex="1"></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-md-6">
                                    <div class="col-md-5 title">Address:</div>
                                    <div class="col-md-7"><input type="text" name="companyAddress"
                                            v-model="user.STREETADDRESS" value="" readonly tabindex="1"></div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="col-md-5 title">City:</div>
                                    <div class="col-md-7"><input type="text" name="companyCity" v-model="user.CITY"
                                            value="" readonly tabindex="1"></div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="col-md-5 title">State/Province:</div>
                                    <div class="col-md-7"><input type="text" name="companyState" v-model="user.STATE"
                                            value="" readonly tabindex="1"></div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="col-md-5 title">Zip/Postal Code:</div>
                                    <div class="col-md-7"><input type="text" name="companyZip" v-model="user.ZIP"
                                            value="" readonly tabindex="1"></div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="col-md-5 title">Country:</div>
                                    <div class="col-md-7"><input type="text" name="companyZip" v-model="user.COUNTRY"
                                            value="" readonly tabindex="1"></div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="col-md-5 title">Phone:</div>
                                    <div class="col-md-7"><input type="text" name="companyPhone" v-model="user.PHONE"
                                            value="" readonly tabindex="1"></div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="col-xs-4 col-md-5 title"></div>
                                    <div class="col-xs-8 col-md-7"><input type="text" name="glcDomain"
                                            v-model="user.GLC_DOMAIN" value="" hidden><input type="text"
                                            name="customer" v-model="user.CUSTOMER" value="" hidden><input type="text"
                                            name="customer" v-model="user.COMMONOWNER" value="" hidden><input
                                            type="text" name="customer" v-model="user.HEADOFFICE" value=""
                                            hidden><input type="text" name="customer" v-model="user.GTN" value=""
                                            hidden><input type="text" name="customer" v-model="user.WD" value=""
                                            hidden><input type="text" name="customer" v-model="user.CTSN" value=""
                                            hidden></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h3 class="blue section-header-text">Step 2: Username</h3>
                    <p>Please choose a username. Must be 6-20 characters (letters, numbers, underscores and hyphens.)
                        Username is case-sensitive.</p>
                    <div class="row">
                        <div class="col-xs-6 col-md-8"><span class="glyphicon glyphicon-ok-sign green ok-input"
                                aria-hidden="true"
                                ng-show="userStatus == 0 &amp;&amp; user.username.length &amp;&amp; !focus" :class="{'ng-hide': isAddHideClassToStep3NoUserName}">
                            </span><input class="form-control" type="text" name="username" v-model="user.username"
                                value="" id="username" ng-focus="focus=true" ng-blur="focus=false" ng-required="true"
                                ng-minlength="6" ng-maxlength="20" ng-pattern="/^[a-zA-Z0-9-_]*$/"
                                :disabled="isAddDisabledToStep2" placeholder="*Username" required @keyup="checkValidUserName" :class="{'ng-invalid-minlength': isInvalidUserName}"></div>
                        <div class="col-xs-6 col-md-4 btn-availability"><button class="btn btn-primary" type="button"
                                @click="checkUserName" ng-model="button" :disabled="isAddDisabledToStep2">Check
                                Availability</button></div>
                        <div class="col-xs-12"><img id="spinnerloading" src="/images/spinner5.gif"
                                ng-show="userloading" :class="{'ng-hide': isAddHideClassToStep2SpinnerLoading}"></div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 form-message">
                            <p ng-show="userStatus == 0 &amp;&amp; user.username.length &amp;&amp; !focus" :class="{'ng-hide': isAddHideClassToStep3NoUserName}"> <span
                                    class="green"> You will receive an email with your temporary password after
                                    completing and submitting the form below. Upon log-in you will be prompted to choose
                                    a new password.</span></p>
                            <p ng-show="userStatus == 1 &amp;&amp; !focus" :class="{'ng-hide': isAddHideClassToStep3UserNameExists}"> <span
                                    class="glyphicon glyphicon-exclamation-sign red" aria-hidden="true"> </span><span
                                    class="red"> Someone already has that username. Try another?</span></p>
                            <p id="userValidate" :class="{'ng-hide': isAddHideClassToStep3EmptyUserName}"><span class="red"> Please
                                    choose a username and click to check for availability.</span></p>
                            <p id="userValidate"><span class="red" ng-show="signupForm.username.$error.minlength" :class="{'ng-hide': isAddHideClassToStep3UserNameMin}">Please
                                    use at least 6 characters.</span><span class="red"
                                    ng-show="signupForm.username.$error.maxlength" :class="{'ng-hide': isAddHideClassToStep3UserNameMax}">Please use less than 20
                                    characters.</span></p>
                            <p id="userValidate"><span class="red" ng-show="signupForm.username.$error.pattern" :class="{'ng-hide': isAddHideClassToStep3UserNamePattern}">Username
                                    must only contain letters, numbers, hypens, and underscores. </span></p>
                        </div>
                    </div>
                    <h3 class="blue section-header-text">Step 3: Account Details </h3>
                    <p>Please provide a unique email address for account setup to maintain individual access for each user.</p>
                    <div class="form-group"> <input class="form-control" type="email" name="email1"
                            v-model="user.email1" ng-disabled="!enableDetailFields" value="" placeholder="*Email"
                            required :disabled="isAddDisabledToStep3" :class="{'ng-invalid-minlength': isInvalidemail1}" @keyup="checkValid3Submit">
                        <div class="row msg-validation"
                            ng-show="signupForm.email1.$touched &amp;&amp; !signupForm.email1.$valid" :class="{'ng-hide': isAddHideClassToStep3Email1}">
                            <div class="col-xs-12"><span class="red">Please enter a valid email address.</span></div>
                        </div>
                    </div>
                    <div class="form-group"><input class="form-control" type="email" name="email2"
                            v-model="user.email2" ng-disabled="!enableDetailFields" value=""
                            placeholder="*Re-enter Email" ng-pattern="user.email1" required :disabled="isAddDisabledToStep3" :class="{'ng-invalid-minlength': isInvalidemail2}" @keyup="checkValid3Submit">
                        <div class="row msg-validation"
                            ng-show="signupForm.email2.$touched &amp;&amp; !signupForm.email2.$valid" :class="{'ng-hide': isAddHideClassToStep3Email2}">
                            <div class="col-xs-12"><span class="red">Please enter a valid email address.</span></div>
                        </div>
                        <div class="row msg-validation" ng-show="signupForm.email2.$error.pattern" :class="{'ng-hide': isAddHideClassToStep3Email2Match}">
                            <div class="col-xs-12"><span class="glyphicon glyphicon-exclamation-sign red"
                                    aria-hidden="true"> </span><span class="red"> Emails have to match!</span></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6"><input class="form-control" type="text" name="fName"
                                v-model="user.fname" ng-disabled="!enableDetailFields" value=""
                                placeholder="*First Name" required :disabled="isAddDisabledToStep3" :class="{'ng-invalid-minlength': isInvalidfname}" @keyup="checkValid3Submit"></div>
                        <div class="form-group col-md-6"><input class="form-control" type="text" name="lName"
                                v-model="user.lname" ng-disabled="!enableDetailFields" value=""
                                placeholder="*Last Name" required :disabled="isAddDisabledToStep3" :class="{'ng-invalid-minlength': isInvalidlname}" @keyup="checkValid3Submit"></div>
                        <div class="form-group text-center">
                            <p><img class="submitSpinner" src="/images/spinner5.gif" ng-show="submitloading" :class="{'ng-hide': isAddHideClassToStep3SpinnerLoading}"></p>
                            <button class="btn btn-primary btn-signup" type="submit" ng-disabled="!enableDetailFields"
                                @click.prevent="signupSubmit" :disabled="isAddDisabledToStep3">SUBMIT</button>
                            <p class="small msg-required">*Required</p>
                        </div>
                    </div>
                </section>
            </form>
        </div>
    </div>
</template>

<script>

const nonsigRegex = RegExp(
  /^[0-9]*$/
  );

const userNameRegex = RegExp(
  /^[a-zA-Z0-9-_]*$/
  );

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );  

import DataService from "../services/data.service";
const dataService = new DataService();  

window.dataLayer = window.dataLayer || []
export default {
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Sign Up | ' + this.$store.getters["getAppTitle"];
      },
    },
  },
  data() {
    return {
            user: {
                nonsig: '',
                BRANDKEY: '',
                CG: '',
                CITY: '',
                COMMONOWNER: '',
                COMPCODE: '',
                COUNTRY: '',
                CUSTOMER: '',
                DC: '',
                DIVISION: '',
                G3X: '',
                GLC_DOMAIN: '',
                HEADOFFICE: '',
                LANGUAGE: '',
                NAME: '',
                PHONE: '',
                SO: '',
                STATE: '',
                STREETADDRESS: '',
                SUBCOMMONOWNER: '',
                TOTALRESULTS: '',
                ZIP: '',
                username: '',
                email1: '',
                email2: '',
                fname: '',
                lname: ''
            },
		isAddHideClassToNonsigNumbersError: true,
        isAddHideClassToStep1SpinnerLoading: true,
        isAddHideClassToStep1Details: true,
		isInvalidnonsig: false,
        isAddHideClassToStep1DetailsInvalid: true,
        isAddHideClassToStep1DetailsValid: true,
        isAddDisabledToStep2: true,
        isAddHideClassToStep3EmptyUserName: true,
        isAddHideClassToStep3NoUserName: true,
        isAddHideClassToStep3UserNameExists: true,
        isAddHideClassToStep2SpinnerLoading: true,
        isAddHideClassToStep3UserNameMin: true,
        isAddHideClassToStep3UserNameMax: true,
        isAddHideClassToStep3UserNamePattern: true,
        isInvalidUserName: false,
        isAddDisabledToStep3: true,
        isInvalidemail1: false,
        isInvalidemail2: false,
        isInvalidfname: false,
        isInvalidlname: false,
        isAddHideClassToStep3Email1: true,
        isAddHideClassToStep3Email2: true,
        isAddHideClassToStep3Email2Match: true,
        isAddHideClassToStep3SpinnerLoading: true,
        isAddHideClassToSingupSuccess: true,
        isAddHideClassToSingupError: true,
		returnedError: '',
        dd: process.env
    }
  },
  computed: {
    
  },
  methods: {
	clearForm(){
            this.user = {
                nonsig: ''
            };
			this.isAddHideClassToNonsigNumbersError= true;
            this.isAddHideClassToStep1SpinnerLoading = true;
            this.isAddHideClassToStep1Details = true;
			this.isInvalidnonsig = false;	
            this.isAddHideClassToStep1DetailsInvalid = true;
            this.isAddHideClassToStep1DetailsValid = true;
            this.isAddDisabledToStep2 = true;
            this.isAddHideClassToStep3EmptyUserName = true;
            this.isAddHideClassToStep3NoUserName = true;
            this.isAddHideClassToStep3UserNameExists = true;
            this.isAddHideClassToStep2SpinnerLoading = true;
            this.isAddHideClassToStep3UserNameMin = true;
            this.isAddHideClassToStep3UserNameMax = true;
            this.isAddHideClassToStep3UserNamePattern = true;
            this.isInvalidUserName = false;
            this.isAddDisabledToStep3 = true;
            this.isInvalidemail1 = false;
            this.isInvalidemail2 = false;
            this.isInvalidfname = false;
            this.isInvalidlname = false;
			this.returnedError = '';	
	},
	checkValidNonsig(){
		let isValid = true;
		if(!nonsigRegex.test(this.user.nonsig)){
				isValid = false;
				this.isInvalidnonsig = true;
				this.isAddHideClassToNonsigNumbersError = false;
			} else {
				this.isInvalidnonsig = false;
				this.isAddHideClassToNonsigNumbersError = true;
			}
		return isValid;
	},
    async checkNonsig() {
        let isValid = false;
		if (this.checkValidNonsig()) {
			this.isAddHideClassToStep1DetailsInvalid = true;					
            this.isAddHideClassToStep1DetailsValid = true;		
            this.isAddHideClassToStep1SpinnerLoading = false;
            this.isAddDisabledToStep2 = true;
			await dataService.searchByNonsig(this.user.nonsig).then(
				response => {
					if(response.data.data[0].TOTALRESULTS != 1){
						this.isAddHideClassToStep1DetailsInvalid = false;					
                        this.isAddHideClassToStep1DetailsValid = true;
					} else {
                        this.user.BRANDKEY = response.data.data[0].BRANDKEY;
                        this.user.CG = response.data.data[0].CG;
                        this.user.CITY = response.data.data[0].CITY;
                        this.user.COMMONOWNER = response.data.data[0].COMMONOWNER;
                        this.user.COMPCODE = response.data.data[0].COMPCODE;
                        this.user.COUNTRY = response.data.data[0].COUNTRY;
                        this.user.CUSTOMER = response.data.data[0].CUSTOMER;
                        this.user.DC = response.data.data[0].DC;
                        this.user.DIVISION = response.data.data[0].DIVISION;
                        this.user.G3X = response.data.data[0].G3X;
                        this.user.GLC_DOMAIN = response.data.data[0].GLC_DOMAIN;
                        this.user.HEADOFFICE = response.data.data[0].HEADOFFICE;
                        this.user.LANGUAGE = response.data.data[0].LANGUAGE;
                        this.user.NAME = response.data.data[0].NAME;
                        this.user.PHONE = response.data.data[0].PHONE;
                        this.user.SO = response.data.data[0].SO;
                        this.user.STATE = response.data.data[0].STATE;
                        this.user.STREETADDRESS = response.data.data[0].STREETADDRESS;
                        this.user.SUBCOMMONOWNER = response.data.data[0].SUBCOMMONOWNER;
                        this.user.TOTALRESULTS = response.data.data[0].TOTALRESULTS;
                        this.user.ZIP = response.data.data[0].ZIP;
						this.isAddHideClassToStep1DetailsInvalid = true;					
                        this.isAddHideClassToStep1DetailsValid = false;	
                        this.isAddDisabledToStep2 = false;	
                        isValid = true;
					}
					this.isAddHideClassToModalContact = true;
					this.isAddHideClassToModalThankYou = false;		                   
                    
				}
				).catch(error => { 						
						this.returnedError = error.response.data.message;	
				} ).    finally(() =>{
                          this.isAddHideClassToStep1SpinnerLoading = true;
                          this.isAddHideClassToStep1Details = false;
                       
                });							
		}
        return isValid;
    },
	checkValidUserName(){
		let isValid = true;
        this.isAddHideClassToStep3EmptyUserName = true;
        this.isAddHideClassToStep3NoUserName = true;
        this.isAddHideClassToStep3UserNameExists = true;
        this.isInvalidUserName = false;
		if(!userNameRegex.test(this.user.username)){
			isValid = false;
            this.isAddHideClassToStep3UserNamePattern = false;
		} else {
            this.isAddHideClassToStep3UserNamePattern = true;
        }
        if(this.user.username.length < 6){
            isValid = false;
            this.isAddHideClassToStep3UserNameMin = false;
		} else {
            this.isAddHideClassToStep3UserNameMin = true;
        }
        if(this.user.username.length >= 20){
            isValid = false;        
            this.isAddHideClassToStep3UserNameMax = false;
        } else {
            this.isAddHideClassToStep3UserNameMax = true;
        }
        if(isValid){
            this.isInvalidUserName = false;
        } else {
            this.isInvalidUserName = true;
        }
		return isValid;
	},    
    async checkUserName() {
        let isValid = false;
        if(this.checkValidUserName()) {
            this.isAddHideClassToStep3EmptyUserName = true;
            this.isAddHideClassToStep3NoUserName = true;
            this.isAddHideClassToStep3UserNameExists = true;   
            this.isAddHideClassToStep3UserNameMin = true;
            this.isAddHideClassToStep3UserNameMax = true;
            this.isAddHideClassToStep3UserNamePattern = true;        
            if (this.user.username == '') {
                this.isAddHideClassToStep3EmptyUserName = false;
            } else {
                this.isAddHideClassToStep2SpinnerLoading = false;
                await dataService.getUserName(this.user.username).then(
                    response => {
                        if(response.data.data == 0){
                            this.isAddHideClassToStep3NoUserName = false;		
                            this.isAddHideClassToStep3UserNameExists = true;
                            this.isAddDisabledToStep3 = false;
                            isValid = true;
                        } else {
                            this.isAddHideClassToStep3NoUserName = true;
                            this.isAddHideClassToStep3UserNameExists = false;
                        }
                        
                    }
                    ).catch(error => { 						
                            this.returnedError = error.response.data.message;	
                    } ).    finally(() =>{
                        this.isAddHideClassToStep2SpinnerLoading = true;
                        
                    });							
            }
        }
        return isValid;
    },
    clearStep3(){
            this.email1 = '';
            this.email2 = '';
            this.fname = '';
            this.lname = '';
            this.isInvalidemail1 = false;
            this.isInvalidemail2 = false;
            this.isInvalidfname = false;
            this.isInvalidlname = false;            
			this.isAddDisabledToStep3 = true;
	},
    checkValid3Submit(){
		let isValid = true;
        this.isAddHideClassToStep3Email1 = true;
        this.isAddHideClassToStep3Email2 = true;
        this.isAddHideClassToStep3Email2Match = true;
        this.isInvalidemail1 = false;
        this.isInvalidemail2 = false;
        this.isInvalidfname = false;
        this.isInvalidlname = false; 
		if(!emailRegex.test(this.user.email1)){
			isValid = false;
            this.isInvalidemail1 = true;
            this.isAddHideClassToStep3Email1 = false;
		} else {
            this.isInvalidemail1 = false;
            this.isAddHideClassToStep3Email1 = true;
        }
		if(!emailRegex.test(this.user.email2)){
			isValid = false;
            this.isInvalidemail2 = true;
            this.isAddHideClassToStep3Email2 = false;
		} else {
            this.isInvalidemail2 = false;
            this.isAddHideClassToStep3Email2 = true;
        }   
		if(this.user.email2 != this.user.email1){
			isValid = false;
            this.isInvalidemail2 = true;
            this.isAddHideClassToStep3Email2Match = false;
		} else {
            this.isInvalidemail2 = false;
            this.isAddHideClassToStep3Email2Match = true;
        }             
        if(this.user.fname.length == 0){
            isValid = false;
            this.isInvalidfname = true;
		} else {
            this.isInvalidfname = false;
        }
        if(this.user.lname.length == 0){
            isValid = false;
            this.isInvalidlname = true;
		} else {
            this.isInvalidlname = false;
        }
		return isValid;
	},
    async signupSubmit() {
        this.isAddHideClassToStep3SpinnerLoading = false;
        this.isAddHideClassToSingupSuccess = true;
        this.isAddHideClassToSingupError = true;
        this.returnedError = '';
        if(await this.checkNonsig() && await this.checkUserName() && this.checkValid3Submit()) {
            await dataService.createUserInSuccessFactors(this.user).then(
				response => {
					console.log(response.data.message);
                    window.dataLayer.push({'event': 'formSubmitted', 'formName': 'Sign Up'})
                    this.isAddHideClassToSingupSuccess = false;
                    //send email to user with account confirmation and info
                                const emailData = {
                                    'from': process.env.VUE_APP_EMAIL_FROM,
                                    'replyto': process.env.VUE_APP_EMAIL_GLC,
                                    'to': this.user.email1,
                                    'subject': 'Your Goodyear Learning Center Account has been created',
                                    'html': 'Thank you for registering with The Goodyear Learning Center.  Please use the following to log in and start learning today. <br/><br/>Log in at: <a href="https://goodyear.plateau.com/learning/user/nativelogin.jsp">The Goodyear Learning Center Portal</a> <br/> User Name: ' + this.user.username + '<br/>Password: Goodyear1 <br/><br/>If you have any questions or would like further assistance, please email us at <a href="mailto:glc@goodyear.com">glc@goodyear.com</a>.' // html body

                                }
                                dataService.sendContactUs(emailData).then(
                                    response => {
                                        console.log(response);			
                                    }
                                    ).catch(error => { 						
                                        console.log(error.response);			
                                    } );
            
                                    
                    //Create User in GAIMS. We will check against this when the user checks username availability.                    
                                dataService.createUserinGAIMSUrl(this.user.username).then(
                                    response => {
                                        console.log(response);			
                                    }
                                    ).catch(error => { 						
                                        console.log(error.response);			
                                    } );  
                                   
				}
				).catch(error => { 						
						this.returnedError = error.response.data.message;	
                        this.isAddHideClassToSingupError = false;
				} ).    finally(() =>{
                          window.scrollTo(0,0);
                          this.isAddHideClassToStep3SpinnerLoading = true;
                });
        }
        else{
            this.isAddHideClassToStep3SpinnerLoading = true;
        }
    }
  }
  
};

</script>
